<template>
    <swiper class="swiper-container"
        :slides-per-view="1"
        :space-between="50"
        :pagination="{ clickable: true }"
        :autoplay="{ autoplay: true }"
        loop
    >
      <swiper-slide @click="toProductDetail(banner.productno)" class="swiper-slide" v-for="banner in bannerList" :key="banner.id"><img :src="banner.pic_url"></swiper-slide>
      <div class="swiper-pagination"></div>
    </swiper>

</template>

<script>

//引入swiper组件
import { Swiper, SwiperSlide } from 'swiper/vue';

//引入swiper样式（swiper6的样式引入方法）
import "swiper/swiper-bundle.css";
import SwiperCore, {Navigation, Pagination, Scrollbar, A11y, Autoplay} from "swiper";

//使用swiper组件
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);


import {useRouter} from 'vue-router'
import api from '../api'
import {reactive} from "vue";
import {refreshSitenoAndFansno} from "../util/util"

export default {
  name: "HeaderSwiper",
  props:{
    siteno:String,
    fansno:String
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {

    refreshSitenoAndFansno(props.siteno, props.fansno)

    const router = useRouter()

    const bannerList = reactive([])
    api.getBanners().then(response => {
      bannerList.push(...response)
    })

    function toProductDetail(productno){
      router.push(`/product?productno=${productno}`)
    }

    return {
      bannerList,
      toProductDetail,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
}
</script>


<style scoped>


/***************** swiper ******************/

.swiper-container {
  margin: 12px 0 0 ;
  border-radius: 8px;
  overflow: hidden;
  --swiper-theme-color: #fff;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



</style>
