<template>

  <ul class="status-container">
    <li class="status-item" :class="{'active': status == -1}" @click="getOrderItems(-1)">
      <span class="status-name">全部</span>
      <span class="selected-line"></span>
    </li>
    <li class="status-item" :class="{'active': status == 0}" @click="getOrderItems(0)">
      <span class="status-name">待预约</span>
      <span class="selected-line"></span>
    </li>
    <li class="status-item" :class="{'active': status == 1}" @click="getOrderItems(1)">
      <span class="status-name">待使用</span>
      <span class="selected-line"></span>
    </li>
    <li class="status-item" :class="{'active': status == 2}" @click="getOrderItems(2)">
      <span class="status-name">已完成</span>
      <span class="selected-line"></span>
    </li>
    <li class="status-item" :class="{'active': status == 3}" @click="getOrderItems(3)">
      <span class="status-name">售后/退换</span>
      <span class="selected-line"></span>
    </li>
  </ul>

  <div class="no-data" v-if="!have_data">
    <img src="https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_no_order.png">
    <span class="no-data-desc">咱时没有订单</span>
  </div>

  <ul class="data-container" v-if="orderItemList.length > 0">
    <li class="data-item" v-for="orderItem of orderItemList" :key="orderItem.id">
      <div class="flow-no-container">
        <div class="flow-no">
          <i class="iconfont icon-liushuihao"></i>
          <span>订单号: {{orderItem.item_no}}</span>
        </div>
        <span class="order-status unbook" v-if="orderItem.use_status == 0">待预约</span>
        <span class="order-status unuse" v-if="orderItem.use_status == 1">待使用</span>
        <span class="order-status used" v-if="orderItem.use_status == 2">已使用</span>
        <span class="order-status cancel" v-if="orderItem.use_status == 3">
          <span v-if="orderItem.refund_status == 1 || orderItem.refund_status == 2 || orderItem.refund_status == 3">退款中</span>
          <span v-else-if="orderItem.refund_status == 4">退款成功</span>
          <span v-else-if="orderItem.refund_status == 5">退款失败</span>
        </span>
      </div>

      <div class="product-info" @click="gotoProduct(orderItem.productno)">
        <div class="product-pic" :style="{backgroundImage: 'url(' + orderItem.cover_pic + ')'}"></div>
        <div class="info">
          <span class="title">{{orderItem.title}}</span>
          <span class="desc">{{orderItem.description}}</span>
        </div>
      </div>


      <div class="user-info">
        <div class="user-title-container">
          <div class="user-title">
            <i class="iconfont icon-user"></i>
            <span>预留信息（使用者）</span>
          </div>
          <span class="more-shop" @click="gotoChgUser(orderItem.id)">修改</span>
        </div>
        <div class="user-detail">
          <div class="detail-item">
            <i class="iconfont icon-point-01"></i>
            <span class="detail-title">姓名：</span>
            <span class="detail-content">{{orderItem.user_name}}</span>
          </div>
          <div class="detail-item">
            <i class="iconfont icon-point-01"></i>
            <span class="detail-title">手机：</span>
            <span class="detail-content">{{orderItem.user_phone}}</span>
          </div>
          <div class="detail-item">
            <i class="iconfont icon-point-01"></i>
            <span class="detail-title">备注：</span>
            <span class="detail-content">{{orderItem.user_remark}}</span>
          </div>
        </div>
      </div>

      <div class="shop-info">
        <div class="shop-title-container">
          <div class="shop-title">
            <i class="iconfont icon-shop"></i>
            <span>适用店铺（共{{orderItem.stores.length}}家店）</span>
          </div>
          <span class="more-shop" @click="gotoStoreList(orderItem.productno)">查看全部</span>
        </div>
        <!--只展示一个店铺信息-->
        <div v-if="orderItem.stores.length > 0" class="shop-item">
          <i class="iconfont icon-righta"></i>
          <div class="shop-detail" @click="gotoStoreList(orderItem.productno)">
            <span class="shop-name">{{orderItem.stores[0].name}}</span>
            <span class="shop-address">{{orderItem.stores[0].address}}</span>
          </div>
          <a :href="'tel:' + orderItem.stores[0].service_mobile"><i class="iconfont icon-phone"></i></a>
        </div>

      </div>

      <div class="price-container">
        <span class="time-info">时间：{{orderItem.add_time}}</span>
        <div class="price-info">
          <span class="pay-desc">实付款：</span>
          <span class="money-tag num-font">¥</span>
          <span class="money-amount">{{orderItem.item_fee}}</span>
        </div>
      </div>
      <div v-if="orderItem.item_no.indexOf('-')>-1" class="split-info">说明：实付款为拆分后的金额（即每份的价格）</div>

      <div class="oper-container">
        <div v-if="orderItem.use_status == 0" class="btn" @click="gotoStoreListWithPhone(orderItem.productno, orderItem.user_phone)">
          <i class="iconfont icon-phone"></i>
          <span class="btn-desc">去预约</span>
        </div>
        <div v-if="orderItem.use_status <= 1" class="btn" @click="gotoUsedCode(orderItem.productno, orderItem.user_phone)">
          <i class="iconfont icon-qrcode"></i>
          <span class="btn-desc">出示消费码</span>
        </div>
      </div>

    </li>

  </ul>
  <div v-else class="no-data">
    <img src="https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/icon_no_collection.png">
    <span class="no-data-desc1" v-if="status == -1">暂时没有订单</span>
    <span class="no-data-desc1" v-if="status == 0">暂时没有待预约订单</span>
    <span class="no-data-desc1" v-if="status == 1">暂时没有待使用订单</span>
    <span class="no-data-desc1" v-if="status == 2">暂时没有已完成订单</span>
    <span class="no-data-desc1" v-if="status == 3">暂时没有取消的订单</span>
    <span class="no-data-desc2">~ 快去挑选喜欢的产品吧 ~</span>
  </div>

</template>

<script>
import {reactive, ref} from "vue";

import {useRoute, useRouter} from 'vue-router'
import api from "../../api";
import {fenToYuan} from "../../util/util";

export default {
  name: "Orders",
  setup(){
    let have_data = ref(true)

    const route = useRoute ()
    let status = ref(route.query.status)
    console.log("this is orders status.001")
    console.log(status.value)


    const router = useRouter()

    function gotoUsedCode(orderItemId){
      router.push("/usedCode?orderItemId=" + orderItemId)
    }

    function gotoChgUser(orderItemId){
      router.push("/changeUser?orderItemId=" + orderItemId)
    }

    function gotoProduct(productno){
      router.push("/product?productno=" + productno)
    }

    function gotoStoreList(productno){
      router.push('/storeList?productno=' + productno)
    }

    function gotoStoreListWithPhone(productno, phone){
      router.push('/storeList?productno=' + productno + '&phone=' + phone)
    }


    const orderItemList = reactive([])
    function getOrderItems(new_status){

      console.log("=============888")
      console.log(new_status)

      status.value = new_status

      //首先情况数组中原来的数据
      orderItemList.splice(0, orderItemList.length)

      api.getOrderItems({
        status:new_status
      }).then(rsp => {
        orderItemList.push(...rsp)

        for(var orderItem of orderItemList) {
          orderItem.item_fee = fenToYuan(orderItem.item_fee)
        }

        console.log("=====991")
        console.log(orderItemList)
      })
    }


    getOrderItems(status.value)

    return {
      have_data,
      status,
      getOrderItems,
      orderItemList,
      gotoStoreList,
      gotoStoreListWithPhone,
      gotoProduct,
      gotoChgUser,
      gotoUsedCode,
    }
  }
}
</script>

<style scoped>

/*头部*/
.status-container{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 40px;
  padding-top: 4px;
  background-color: #ffffff;
}

.status-item{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.status-name{
  color: #666666;
  margin-bottom: 6px;
}

.active .status-name{
  color: #d73121;
  font-weight: 600;
}

.selected-line{
  display: inline-block;
  width: 20px;
  height: 2px;
}

.active .selected-line{
  background-color: #d73121;
  border-radius: 2px;
}

/******* no-data *******/
.no-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 150px;
}

.no-data img{
  width: 50%;
}

.no-data-desc{
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
}

/**** 数据 ****/
.data-container{
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin-top: 10px;
  margin-bottom: 20px;
}

.data-item{
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: #ffffff;
  margin: 10px 10px 4px 10px;
  border-radius: 10px;
  padding: 0 10px 10px 10px;
}

/*流水号信息*/
.flow-no-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
}

.flow-no{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 4px;
}

.flow-no i{
  margin-right: 4px;
}

.order-status{
  margin-right: 4px;
}

.order-status.unbook, .order-status.unuse{
  color: #67c23a;
  font-weight: 600;
}

.order-status.used, .order-status.cancel{
  color: #666666;
  font-weight: 600;
}

/*产品信息*/
.product-info{
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px dashed #e2e2e2;
}

.product-pic{
  width: 75px;
  height: 75px;
  border-radius: 8px;
  flex-shrink: 0;
  margin-right: 10px;
  background: bottom center no-repeat;
  background-size: cover;
}

.info{
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.title{
  font-size: 14px;
  font-weight: 600;
  color: #1f1f1f;
  margin-bottom: 10px;

  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
  word-break:break-all;
}

.desc{
  font-size: 12px;
  line-height: 20px;
  color: #656565;

  /*超出2行隐藏 + 省略号*/
  height: auto;
  display:-webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}

/*预留信息（使用者）*/
.user-info{
  padding-bottom: 16px;
  border-bottom: 1px dashed #e2e2e2;
}

.user-title-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
}

.user-title{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 4px;
}

.user-title i{
  margin-right: 4px;
}

.user-detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 6px 0 0 20px;
}

.detail-item{
  display: flex;
  flex-direction: row;
}

.detail-title{
  margin-right: 6px;
  font-size: 13px;
  font-weight: 500;
  color: #2a2a2a;
}

.detail-content{
  font-size: 13px;
  color: #2a2a2a;
}

/*适用店铺*/
.shop-info{
  padding-bottom: 16px;
  border-bottom: 1px dashed #e2e2e2;
}

.shop-title-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
}

.shop-title{
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 4px;
}

.shop-title i{
  margin-right: 4px;
}


.more-shop{
  position: relative;
  color: #969696;
  font-size: 13px;
  margin-right: 15px;
}

.more-shop::after{
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  border-top: 2px solid #969696;
  border-right: 2px solid #969696;
  top: 6px;
  margin-left: 2px;
  transform: rotate(45deg);
}

.shop-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-top: 10px;
}

.shop-item .icon-righta{
  margin-left: 10px;
  margin-right: 10px;
}

.shop-item i{
  font-size: 14px;
  color: #2a2a2a;
}

.shop-detail{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-self: start;
}

.shop-name{
  font-size: 14px;
  font-weight: 600;
  color: #2a2a2a;
}

.shop-address{
  font-size: 12px;
}

.shop-item .icon-phone{
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

/*价格*/
.price-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 4px 0 4px;
  padding-bottom: 10px;
}

.time-info{
  color: #9a9a9a;
  font-size: 12px;
}

.price-info{
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 500;
}
.money-tag{
  margin-right: 2px;
}
.money-tag, .money-amount{
  color: #c24f4a;
}

.split-info{
  color: #9a9a9a;
  font-size: 12px;
  width: 100%;
  margin: 0 0 6px 0;
}

/*操作按钮*/
.oper-container{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
  font-size: 12px;
  color: #112f63;
}

div.btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  border: 1px solid #112f63;
  border-radius: 15px;
  margin-right: 20px;
}

div.btn:last-child{
  margin-right: 6px;
}

div.btn i{
  margin-right: 6px;
}



.no-data{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 150px;
}

.no-data img{
  width: 50%;
}

.no-data-desc1{
  color: #2f2f2f;
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
}

.no-data-desc2{
  color: #999999;
}

</style>
