<template>

  <div @click="toProductDetail(product.productno)" class="product-item">
    <div class="product-pic" :style="{backgroundImage: 'url(' + product.cover_pic + ')'}">
      <div class="distance" v-if="product.distance">
        <img src="@/assets/images/icon_home_location2@3x.png">
        <span class="distance-info">{{product.distance}}</span>
      </div>
      <div class="tag">
        <span v-if="product.stock_num_balance < 50" class="tag-offline">即将售罄</span>
        <span v-if="product.stock_num_balance < 50" class="tag-offline">仅剩{{product.stock_num_balance}}/{{product.stock_num_total}}</span>
        <span  v-if="product.stock_num_balance >= 50" class="tag-qgz">抢购中</span>
        <span class="tag-offline" style="display: none">即将下架</span>
        <span class="tag-offline" style="display: none">12时34分25秒</span>
        <span class="tag-online" style="display: none">即将上线</span>
        <span class="tag-online" style="display: none">12时34分25秒</span>
      </div>
    </div>
    <div class="product-info">
      <span class="product-title">{{product.title}}</span>
      <span class="product-brief">{{product.description}}</span>
      <div class="product-price">
        <span class="price1 num-font">¥</span>
        <span class="price1 num-font">{{product.sale_price_min_yuan}}</span>
        <span class="price2">门市价 ¥{{product.old_price_min_yuan}}</span>
        <span class="price3">销量 {{product.all_sale_num}}</span>
      </div>
      <div class="enroll" style="display: none">
        <span class="enroll-btn">0元试听课</span>
        <span class="enroll-coupon-info">报名即送300元优惠券</span>
        <span class="enroll-num">已报18人</span>
      </div>
    </div>
  </div>

</template>

<script>

import {useRouter} from 'vue-router'

export default {
  name: "ProductItem",
  props:{
    product:Object
  },
  setup(props){
    const router = useRouter()

    console.log(props.product)

    function toProductDetail(productno){
      router.push(`/product?productno=${productno}`)
    }

    return {
      toProductDetail
    }

  }
}
</script>

<style scoped>

/*产品信息*/

.product-item{
  margin: 10px 10px 0 10px;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
}

.product-info{
  display: flex;
  flex-direction: column;
  margin: 10px 14px 0 14px;
}

.product-pic{
  position: relative;
  width: 100%;
  height: 174px;
  background: no-repeat center center;
  background-size: cover;
}

.distance{
  display: flex;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
  height: 20px;
  background-color: rgba(0,0,0,0.5);
  color: #ffffff;
  font-size: 12px;
  padding: 0 4px;
  border-radius: 10px;
}

.distance img{
  width: 15px;
  margin-right: 4px;
}

.distance-info{
  height: 20px;
  line-height: 20px;
}


.tag{
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  bottom: 10px;
  height: 20px;
}

.tag-qgz{
  background-color: #00af48;
  color: #ffffff;
  font-size: 12px;
  padding: 0 6px;
  border-radius: 10px;
  height: 20px;
  line-height: 20px;
}

.tag-offline{
  background-color: #f75151;
  color: #ffffff;
  font-size: 12px;
  padding: 0 6px;
  border-radius: 10px;
  margin-right: 4px;
  height: 20px;
  line-height: 20px;
}


.tag-online{
  background-color: #fdd051;
  color: #004200;
  font-size: 12px;
  padding: 0 6px;
  border-radius: 10px;
  margin-right: 4px;
  height: 20px;
  line-height: 20px;
}

.product-title{
  color: #292f36;
  font-size: 16px;
  font-weight: 700;
}

.product-brief{
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin: 6px 0 12px 0;

  /*超出2行隐藏 + 省略号*/
  height: auto;
  display:-webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2;
}

.product-price{
  margin-bottom: 6px;
}

.product-price .price1{
  color: #ff4b3b;
  font-weight: 500;
  font-size: 20px;
}


.product-price .price2{
  color: #666666;
  font-weight: 400;
  font-size: 10px;
  text-decoration: line-through;
  margin-left: 6px;
}

.product-price .price3{
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  margin-left: 6px;
}

.enroll{
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 6px;
}

.enroll-btn{
  height: 30px;
  background: linear-gradient(to right, #ffdc4e, #ffc82d);
  border-radius: 15px;
  text-align: center;
  line-height: 30px;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  padding: 0 10px;
}

.enroll-coupon-info{
  margin-left: 6px;
  /*color: #ff4b3b;*/
  color: #999999;
}

.enroll-num{
  padding: 0 6px;
  margin-left: 6px;
  height: 18px;
  border: 1px solid #f5dac5;
  font-size: 12px;
  color: #ff8207;
  border-radius: 6px;
  line-height: 18px;
}



</style>
