<template>
<!--  搜索页-->

  <div class="search-container">
    <i @click="gotoPre" class="back-tag iconfont">&#xe658;</i>
    <div class="input-container">
      <input type="text" placeholder="搜你喜欢！" ref="keywordInput" v-model="keyword">
      <i v-if="keyword.length > 0" class="del-tag iconfont" @click="clearKeyword">&#xe651;</i>
    </div>
    <span class="search-btn" @click="searchProducts">搜索</span>
  </div>

  <div v-if="!haveSearch&&keyword_history_list.length>0" class="search-history">
    <div class="history-title">
      <span>搜索历史</span>
      <i @click="clearSearchHistory" class="iconfont">&#xe6b4;</i>
    </div>
    <ul class="history-item">
      <li v-for="(history_keyword, index) of keyword_history_list" :key="index" @click="searchHistory(history_keyword)">{{ history_keyword }}</li>
    </ul>
  </div>

  <div v-if="haveSearch&&productList.length==0" class="no-data">
    <img src="@/assets/images/no_data.png">
    <span>暂时没找到您要的东西哦</span>
  </div>


  <!--产品列表-->
  <div v-if="haveSearch" class="product-container">

    <span v-if="haveSearch&&productList.length>0">共为您找到{{productList.length}}条数据。</span>

    <ProductItem v-for="productInfo of productList" :key="productInfo.id" :product="productInfo"></ProductItem>

  </div>

</template>

<script>
import {ref, onMounted, reactive} from 'vue'
import ProductItem from "../components/ProductItem";
import {useRouter} from 'vue-router'
import api from "../api";
import {isEmptyStr,fenToYuan} from "../util/util";

export default {
  name: "Search",
  components: {
    ProductItem
  },
  setup(){

    //标记是否已经搜索：false 未搜索， true 已经搜索
    let haveSearch = ref(false)

    let keyword = ref('')
    function clearKeyword(){
      keyword.value = ''
      haveSearch.value = false
    }

    const router = useRouter()

    function gotoPre(){
      router.go(-1);
    }

    //读取历史搜索信息
    const keyword_history_list = reactive([])
    let history_str = localStorage.getItem("tuan_keyword_history")
    if(!isEmptyStr(history_str)){
      let a = JSON.parse(history_str)
      keyword_history_list.push(...a)
    }

    function clearSearchHistory(){
      keyword_history_list.splice(0, keyword_history_list.length)
      localStorage.removeItem("tuan_keyword_history")
    }

    const productList = reactive([])

    function searchProducts(){
      //首先产品列表（防止之前有数据）
      productList.splice(0, productList.length)

      //标记已经触发搜索
      haveSearch.value = true

      //保存搜索记录
      if(!isEmptyStr(keyword.value) && keyword_history_list.indexOf(keyword.value)==-1)
      {
        keyword_history_list.push(keyword.value)
        localStorage.setItem("tuan_keyword_history", JSON.stringify(keyword_history_list))
      }


      api.searchProductList({
        key_word: keyword.value
      }).then(rsp => {
        productList.push(...rsp)

        for(var product of productList) {
          product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
          product.old_price_min_yuan = fenToYuan(product.old_price_min)
        }
      })

    }

    function searchHistory(history_keyword){
      keyword.value = history_keyword
      searchProducts()
    }



    const keywordInput = ref(null)
    onMounted(() => {
      keywordInput.value.focus()
    })

    return {
      productList,
      keywordInput,
      gotoPre,
      keyword,
      clearKeyword,
      searchProducts,
      haveSearch,
      keyword_history_list,
      clearSearchHistory,
      searchHistory
    }
  }
}
</script>

<style scoped>
/****************** 搜索框 ******************/
.search-container{
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 15px;
  padding-top: 20px;
  padding-right: 20px;;
  z-index: 2;
  background-color: #f6f6f6;
}

.back-tag{
  font-size: 40px;
}

.search-btn{
  width: 48px;
  height: 26px;
  background: linear-gradient(to right, #ffdc4e, #ffc82d);
  color: #000000;
  font-size: 12px;
  border-radius: 25px;
  text-align: center;
  line-height: 26px;
}

.input-container{
  flex: 1;
  display: flex;
  position: relative;
  margin: 0 10px 0 15px;
}

.input-container input{
  height: 30px;
  flex: 1;
  border: 1px solid #fae198;
  border-radius: 17px;
  padding-left: 15px;
  padding-right: 30px;
}

.del-tag{
  position: absolute;
  font-size: 20px;
  z-index: 2;
  right: 6px;
  top: 2px;
}


.search-history{
  display: flex;
  margin: 40px 25px 0 25px;
  flex-direction: column;
  align-items: flex-start;
}

.history-title{
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.history-title span{
  font-size: 16px;
  font-weight: 500;
  color: #020202;
  letter-spacing: 1px;
}

.history-title i{
  font-size: 18px;
}

.history-item{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  font-size: 12px;
}

.history-item li{
  padding: 8px 12px;
  background-color: #ffffff;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 20px;
}


.no-data{
  display: flex;
  margin-top: 100px;
  flex-direction: column;
  align-items: center;
  color: #666666;
}

.no-data img{
  width: 60%;
  margin-bottom: 30px;
}


.product-container{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.product-container span{
  margin-left: 20px;
}


</style>
