<template>
<!--  首页-->

  <div class="home">

    <!--头部-->
    <HomeHeader :siteno="siteno" :fansno="fansno"></HomeHeader>

    <!--排序菜单-->
    <div class="sort-menu-container">
      <span class="item" :class="{'active': current_sort_type == 0,}" @click="getProducts(0)">精品推荐</span>
      <span class="item" :class="{'active': current_sort_type == 1,}" @click="getLocation(1)">距离最近</span>
      <span class="item" :class="{'active': current_sort_type == 2,}" @click="getProducts(2)">销量最多</span>
      <span class="item" :class="{'active': current_sort_type == 3,}" @click="getProducts(3)">无需预约</span>
    </div>

      <!--产品列表-->
    <div class="product-container">

      <ProductItem v-for="productInfo of productList" :key="productInfo.id" :product="productInfo"></ProductItem>

    </div>

    <!--底部菜单-->
    <FooterMenu></FooterMenu>

  </div>

</template>

<script>

import wx from 'weixin-js-sdk'
import {isEmptyStr} from "../util/util"

import ProductItem from "../components/ProductItem";
import HomeHeader from "../components/HomeHeader";
import FooterMenu from "../components/FooterMenu";
import api from "../api"
import {fenToYuan} from "../util/util";


import {useRoute } from 'vue-router';
import {reactive, ref} from "vue";

export default {
  name: "Home",
  components: {
    FooterMenu,
    HomeHeader,
    ProductItem
  },
  setup() {

    const route = useRoute ()

    //接收参数 并 缓存
    let siteno = ref(route.query.siteno);
    let fansno = ref(route.query.fansno);
    if(!isEmptyStr(siteno.value)){
      localStorage.setItem("tuan_siteno", siteno.value)
    }
    if(!isEmptyStr(fansno.value)){
      localStorage.setItem("tuan_fansno", fansno.value)
    }



    //这里获取地理位置 begin ************************
    //第一步，加载config
    //加载微信config end
    let current_url = window.location.href
    api.getJsapiData(current_url).then(rsp => {
      //加载微信config begin:
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: rsp.appId, // 和获取Ticke的必须一样------必填，公众号的唯一标识
        timestamp: rsp.timestamp, // 必填，生成签名的时间戳
        nonceStr: rsp.nonceStr, // 必填，生成签名的随机串
        signature: rsp.signature,// 必填，签名，见附录1
        jsApiList: [
          'onMenuShareAppMessage',
          'onMenuShareTimeline',
          'previewImage',
          'getLocalImgData',
          'downloadImage',
          'uploadImage',
          'chooseImage',
          'openLocation',
          'getLocation',
          'hideMenuItems'
        ]
        // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });

      // config信息验证成功会执行ready函数
      wx.ready(() => {
        console.log("This is wx.ready.")
      })

      // config信息验证失败会执行error函数
      wx.error(function (res) {
        console.log("This is wx.error.")
        console.log(res)
      });

      //加载微信config end

      //进入页面后，自动调用一次获取函数
      getLocation(0)
    })

    //第二步，触发获取位置的具体操作
    //获取位置成功的同时，获取产品列表，参数sort_type觉得如何获取产品列表
    function getLocation(sort_type) {

      wx.getLocation({
        type: 'gcj02', //返回可以用于wx.openLocation的经纬度
        success(res) {
          //成功是，res为： {latitude: 34.86426, longitude: 117.55601, errMsg: "getLocation:ok"}
          //授权成功：成功后保存粉丝的经纬信息（保存经纬度信息成功后，再重新调一次api获取产品信息）
          api.updateLnglat(res.longitude, res.latitude).then(rsp => {
            console.log(rsp)
            // alert(100)
            getProducts(sort_type)
          })
        },
        fail: function (err) {
          console.log("err is :")
          console.log(err)
        },
        complete: function (e) {
          //不论成功与失败，都会执行这里
          //客户同意授权时， e为：{latitude: 34.86426, longitude: 117.55601, errMsg: "getLocation:ok"}
          //客户拒绝授权时： e为：{errMsg: "getLocation:cancel"}

          console.log("complete is :")
          console.log(e)

          // alert(200)
          //客户取消授权（授权失败后，强制按照“精品推荐”获取产品列表）
          if (e.errMsg.includes("cancel"))
          {
            // alert(300)
            getProducts(0)
          }

        }
      })

    }
    //这里获取地理位置 end ************************


    let current_sort_type = ref(0)

    const productList = reactive([])

    // api.selectProductList({
    //   menu_type: 0,
    //   sort_type: 0
    // }).then(rsp => {
    //   productList.push(...rsp)
    //
    //   for(var product of productList) {
    //     product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
    //     product.old_price_min_yuan = fenToYuan(product.old_price_min)
    //   }
    // })

    function getProducts(sort_type){

      productList.splice(0, productList.length)

      current_sort_type.value = sort_type

      if(3 == sort_type){
        api.selectProductList({
          menu_type: 0,
          appointment: 1
        }).then(rsp => {
          productList.push(...rsp)

          for(var product of productList) {
            product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
            product.old_price_min_yuan = fenToYuan(product.old_price_min)
          }
        })
      }
      else
      {
        api.selectProductList({
          menu_type: 0,
          sort_type: sort_type
        }).then(rsp => {
          productList.push(...rsp)

          for(var product of productList) {
            product.sale_price_min_yuan = fenToYuan(product.sale_price_min)
            product.old_price_min_yuan = fenToYuan(product.old_price_min)
          }
        })
      }

    }


    return {
      productList,
      getProducts,
      current_sort_type,
      siteno,
      fansno,
      getLocation
    }
  }
}
</script>

<style scoped>

.home{
  padding-bottom: 70px;
}

.product-container{
  min-height: 800px;
}


/*排序*/
.sort-menu-container{
  display: flex;
  align-items: center;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 10px;
}

.item{
  width: 25%;
  height: 48px;
  line-height: 48px;
  text-align: center;
}

.sort-menu-container .active{
  font-size: 16px;
  font-weight: 700;
  background: url('../assets/images/icon_learn_selected.c2ffbed4.png') no-repeat bottom center;
  background-size: 80%;
}

</style>
