import {get, post} from "./api";

const BASE_URL = 'https://api.meitianxiaodian.com/tuan/'

//********* 移动端

// 1、获取站点信息
async function getSiteInfo(){
    let result = await get(`${BASE_URL}mobile/mobile/getSiteInfo`)
    console.log("api getSiteInfo result:")
    console.log(result)
    return result.data;
}

// 2、获取站点的区信息
async function getDistricts(){
    let result = await get(`${BASE_URL}mobile/mobile/getDistricts`)
    console.log("api getDistricts result:")
    console.log(result)
    return result.data;
}

// 3、获取站点的banner信息
async function getBanners(){
    let result = await get(`${BASE_URL}mobile/mobile/getBanners`)
    console.log("api getBanners result:")
    console.log(result)
    return result.data;
}

// 4、获取爆品排行榜
async function getTopProducts(){
    let result = await get(`${BASE_URL}mobile/product/getTopProducts`)
    console.log("api getTopProducts result:")
    console.log(result)
    return result.data;
}

// 5、获取秒杀
async function getFlashSale(){
    let result = await get(`${BASE_URL}mobile/product/getFlashSale`)
    console.log("api getFlashSale result:")
    console.log(result)
    return result.data;
}

//6，获取产品列表（不分页）
async function selectProductList(data){
    console.log("in api selectProductList")
    console.log(data)
    let result = await post(`${BASE_URL}mobile/product/selectProductList`, data)
    console.log("api selectProductList result:")
    console.log(result)
    return result.data;
}

//7，搜索产品列表（不分页）
async function searchProductList(data){
    let result = await post(`${BASE_URL}mobile/product/searchProductList`, data)
    console.log("api searchProductList result:")
    console.log(result)
    return result.data;
}

//8，获取jsapi的相关数据
async function getJsapiData(url){
    const data = {'url': url}
    let result = await post(`${BASE_URL}wx/webview/jsapi`, data)
    console.log("api getJsapiData result:")
    console.log(result)
    return result.data;
}


//9，保存经纬度
async function updateLnglat(lng, lat){
    const data = {'lng': lng, 'lat': lat}
    let result = await post(`${BASE_URL}mobile/mobile/updateLnglat`, data)
    console.log("api updateLnglat result:")
    console.log(result)
    return result.data;
}

//10，获取单个产品的详情
async function getProduct(data){
    let result = await post(`${BASE_URL}mobile/product/getProduct`, data)
    console.log("api getProduct result:")
    console.log(result)
    return result.data;
}

//11，获取单个产品的购买须知
async function getProductNoticeList(data){
    let result = await post(`${BASE_URL}mobile/product/getProductNoticeList`, data)
    console.log("api getProductNoticeList result:")
    console.log(result)
    return result.data;
}

//12，获取单个产品的套餐列表
async function getProductPackageList(data){
    console.log("api getProductPackageList req:")
    console.log(data)
    let result = await post(`${BASE_URL}mobile/product/getProductPackageList`, data)
    console.log("api getProductPackageList result:")
    console.log(result)
    return result.data;
}

//13，获取单个产品的套餐列表
async function getProductBanners(data){
    let result = await post(`${BASE_URL}mobile/product/getProductBanners`, data)
    console.log("api getProductBanners result:")
    console.log(result)
    return result.data;
}

//14，更新收藏
async function updateCollection(data){
    let result = await post(`${BASE_URL}mobile/product/updateCollection`, data)
    console.log("api updateCollection result:")
    console.log(result)
    return result.data;
}

//15，获取购买者的展示数据
async function getBuyerListForShow(data){
    let result = await post(`${BASE_URL}mobile/product/getBuyerListForShow`, data)
    console.log("api getBuyerListForShow result:")
    console.log(result)
    return result.data;
}

//16，查询某个商户的门店列表
async function getStoresByProduct(data){
    let result = await post(`${BASE_URL}mobile/shop/getStoresByProduct`, data)
    console.log("api getStoresByProduct result:")
    console.log(result)
    return result.data;
}

//17，查询某个商户的详情列表
async function getProductDetailItems(data){
    let result = await post(`${BASE_URL}mobile/product/getProductDetailItems`, data)
    console.log("api getProductDetailItems result:")
    console.log(result)
    return result.data;
}

//18，获取单个产品套餐信息
async function getProductPackage(data){
    let result = await post(`${BASE_URL}mobile/product/getProductPackage`, data)
    console.log("api getProductPackage result:")
    console.log(result)
    return result.data;
}

//19，添加订单（购买行为）
async function addOrder(data){
    console.log("api addOrder req:")
    console.log(data)
    let result = await post(`${BASE_URL}mobile/order/addOrder`, data)
    console.log("api addOrder result:")
    console.log(result)
    return result.data;
}

//20，获取产品收藏列表
async function getProductCollectionRecords(){
    console.log("api getProductCollectionRecords req:")
    let result = await get(`${BASE_URL}mobile/product/getProductCollectionRecords`)
    console.log("api getProductCollectionRecords result:")
    console.log(result)
    return result.data;
}

//21，获取粉丝的订单列表
async function getOrderItems(data){
    console.log("api getOrderItems req:")
    console.log(data)
    let result = await post(`${BASE_URL}mobile/order/getOrderItems`, data)
    console.log("api getOrderItems result:")
    console.log(result)
    return result.data;
}


//22，修改预留信息（消费者）
async function chgUser(data){
    console.log("api chgUser req:")
    console.log(data)
    let result = await post(`${BASE_URL}mobile/order/chgUser`, data)
    console.log("api chgUser result:")
    console.log(result)
    return result;
}


//23，获取某个订单项信息
async function getOrderItemById(data){
    console.log("api getOrderItemById req:")
    console.log(data)
    let result = await post(`${BASE_URL}mobile/order/getOrderItemById`, data)
    console.log("api getOrderItemById result:")
    console.log(result)
    return result.data;
}

//24，获取 待预约 和 待使用的数量
async function getUnbookNumAndUnuseNum(){
    console.log("api getUnbookNumAndUnuseNum req:")
    let result = await get(`${BASE_URL}mobile/order/getUnbookNumAndUnuseNum`)
    console.log("api getUnbookNumAndUnuseNum result:")
    console.log(result)
    return result.data;
}




export default {
    getSiteInfo,
    getDistricts,
    getBanners,
    getTopProducts,
    searchProductList,
    getFlashSale,
    selectProductList,
    getJsapiData,
    updateLnglat,
    getProduct,
    getProductNoticeList,
    getProductPackageList,
    getProductBanners,
    updateCollection,
    getBuyerListForShow,
    getStoresByProduct,
    getProductDetailItems,
    getProductPackage,
    addOrder,
    getProductCollectionRecords,
    getOrderItems,
    chgUser,
    getOrderItemById,
    getUnbookNumAndUnuseNum
}


