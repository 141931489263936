<template>

  <div class="uCode-container">
    <div class="desc">
      <i class="iconfont icon-xiaolaba"></i>
      <span>请妥善保管好自己的核销码</span>
    </div>
    <div class="user-container">
      <div class="user-title">下单时的预留信息：{{phone}}</div>
      <div class="user-title">消费码：3524</div>
    </div>
    <div class="uCode">
      <img src="https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/kefu_qrcode.jpg" alt="">
      <span class="tip">请将二维码展示给商家，或告知手机号和消费码</span>
    </div>
  </div>


</template>

<script>
export default {
  name: "UsedCode"
}
</script>

<style scoped>

/*预留信息*/
.uCode-container{
  display: flex;
  flex-direction: column;
}
.desc{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  background-color: #fdf6ec;
  color: #e6a23c;
}

.desc i{
  margin-right: 10px;
}

.user-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin: 4px 10px 10px 10px;

  font-size: 14px;
}

.user-title{
  font-weight: 600;
  height: 36px;
  line-height: 36px;
}

.uCode{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.uCode img{
  width: 90%;
}

</style>
