import axios from 'axios'

function get (url, data={})
{
    return new Promise(function (resolve, reject) {
        // 执行异步ajax请求
        let promise

        // 拼装query参数数据
        let dataStr = '' //数据拼接字符串
        Object.keys(data).forEach(key => {
            dataStr += key + '=' + data[key] + '&'
        })
        if (dataStr !== '') {
            dataStr = dataStr.substring(0, dataStr.lastIndexOf('&'))
            url = url + '?' + dataStr
        }

        //消息头信息
        const config = {
            headers:{
                fansno: localStorage.getItem('tuan_fansno'),
                siteno: localStorage.getItem("tuan_siteno")
            }
        };

        // 发送get请求
        promise = axios.get(url, config)

        promise.then(function (response) {
            // 成功了调用resolve()
            resolve(response.data)
        }).catch(function (error) {
            //失败了调用reject()
            reject(error)
        })
    })
}

function post (url, data={}) {

    return new Promise(function (resolve, reject) {
        // 执行异步ajax请求
        let promise

        // 'Content-Type':'multipart/form-data',
        // 'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8',

        //消息头信息
        const config = {
            headers:{
                'Content-Type':'multipart/form-data',
                fansno: localStorage.getItem('tuan_fansno'),
                siteno: localStorage.getItem("tuan_siteno")
            }
        };

        // 发送post请求
        promise = axios.post(url, data, config)

        promise.then(function (response) {
            // 成功了调用resolve()
            resolve(response.data)
        }).catch(function (error) {
            //失败了调用reject()
            reject(error)
        })
    })
}

function postWithBody (url, data={}) {

    return new Promise(function (resolve, reject) {
        // 执行异步ajax请求
        let promise

        // 'Content-Type':'multipart/form-data',
        // 'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8',

        //消息头信息
        const config = {
            headers:{
                'Content-Type':'multipart/form-data',
                fansno: localStorage.getItem('tuan_fansno'),
                siteno: localStorage.getItem("tuan_siteno")
            }
        };

        // 发送post请求
        promise = axios.post(url, data, config)

        promise.then(function (response) {
            // 成功了调用resolve()
            resolve(response.data)
        }).catch(function (error) {
            //失败了调用reject()
            reject(error)
        })
    })
}

export {
    get,
    post,
    postWithBody
}
