<template>

  <!--城市切换和客服-->
  <div class="title-container">
    <!--城市切换-->
<!--    <div class="location-container" @click="showChgArea">-->
<!--      <img class="home-location-map" src="@/assets/images/icon_title_map.png">-->
<!--      <span>{{current_district_name}}</span>-->
<!--      <img class="home-location-down" src="@/assets/images/icon_title_map_down.png">-->
<!--    </div>-->
    <div class="logo-title">
      <img class="logo" :src="site_logo" alt="">
      <span class="title">{{site_title}}</span>
    </div>
    <!--客服-->
    <div class="kefu-container" @click="showContact">
      <img class="kefu" src="@/assets/images/icon_home_h5_kefu.png">
      <span class="kefu-desc">客服</span>
    </div>
  </div>

  <teleport to="body">

    <div @click="hideContact" v-if="isShowContact" class="contact-mask">
    </div>
    <div v-if="isShowContact" class="contact-container">
      <img class="contact-img" :src="contact_img">
    </div>

  </teleport>

</template>

<script >

import {ref} from "vue";
import api from "../api"
import {refreshSitenoAndFansno} from "../util/util"

export default {
  name: "Title",
  props:{
    siteno:String,
    fansno:String
  },
  setup(props){

    refreshSitenoAndFansno(props.siteno, props.fansno)

    let site_title = ref()
    let site_logo = ref("https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/logo_64_64.jpg")
    let contact_img = ref("https://tuan2022.oss-cn-hangzhou.aliyuncs.com/sys/tuan_kefu2.png")

    api.getSiteInfo().then(response => {
      site_title.value = response.title
      site_logo.value = response.logo
      contact_img.value = response.kefu_pic
    })

    let isShowContact = ref(false)
    function showContact(){
      isShowContact.value = true
    }

    function hideContact(){
      isShowContact.value = false
    }

    return {
      site_title,
      site_logo,
      contact_img,
      isShowContact,
      showContact,
      hideContact
    }

  }
}




</script>

<style scoped>

/****************** 城市切换和客服 ******************/
.title-container{
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: 44px;
}

/*.location-container{*/
/*  display: flex;*/
/*  align-items: center;*/
/*  font-size: 14px;*/
/*  color: #000000;*/
/*  font-weight: 600;*/
/*}*/

.logo-title{
  display: flex;
  justify-content: left;
  align-items: center;
}

.logo{
  width: 24px;
  height: 24px;
  margin-right: 4px;
  border-radius: 50%;
}

.title{
  font-size: 15px;
  color: #000000;
  font-weight: 600;
}

/*.home-location-map{*/
/*    width: 14px;*/
/*    margin-right: 4px;*/
/*}*/

/*.home-location-down{*/
/*  width: 14px;*/
/*  margin-left: 4px;*/
/*}*/

.kefu-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: #fef3d3;
  border-radius: 15px;
  font-size: 12px;
}

.kefu{
  width: 16px;
  margin-left: 10px;
  margin-right: 4px;
}

.kefu-desc{
  margin-right: 10px;
}

.kefu-qrcode-container{
  position: fixed;
  top: 0;bottom: 0;left: 0;right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.kefu-qrcode{
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  text-align: center;
  width: 300px;
  height: 300px;
  z-index: 3;
}

.kefu-qrcode img{
  width: 100%;
}

/*客服二维码*/
.contact-mask{
  position: fixed;
  top: 0;bottom: 0;left: 0;right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.contact-container{
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 70%;
  max-height: 400px;
  z-index: 11;
  border-radius: 10px;
  overflow: hidden;
}

.contact-img{
  min-width: 100%;
  min-height: 100%;
}


</style>
