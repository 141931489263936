<template>

  <div class="flash-sale-container">
    <div class="title-container">
      <span>限时秒杀</span>
      <div class="time-info">
        <span class="time">{{time_balance.hour}}</span>
        <span class="time-spot">:</span>
        <span class="time">{{time_balance.minute}}</span>
        <span class="time-spot">:</span>
        <span class="time">{{time_balance.second}}</span>
      </div>
    </div>
    <div class="flash-sale-product" @click="toProductDetail(productno)">
      <img class="product" :src="pic_url" alt="">
      <div class="flash-sale-info">
        <div class="old-price-container">
          <div class="old-price">
            <span class="money-tag num-font">¥</span>
            <span class="money-num num-font">{{old_price}}</span>
            <span class="old-price-tag">原价</span>
          </div>
          <span class="title">{{sale_title}}</span>
        </div>
        <div class="new-price-container">
          <div class="new-price">
            <span class="money-tag num-font">¥</span>
            <span class="money-num num-font">{{new_price}}</span>
            <span class="buy-tag">抢</span>
          </div>
          <span class="desc">限时秒杀，立减{{price_gap}}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import {useRouter} from 'vue-router'
import {reactive, ref} from "vue";
import {fenToYuan, calcTimeBalance, refreshSitenoAndFansno} from "../util/util";
import api from "../api"

export default {
  name: "FlashSale",
  props:{
    siteno:String,
    fansno:String
  },
  setup(props){

    refreshSitenoAndFansno(props.siteno, props.fansno)

    const router = useRouter()

    function toProductDetail(productno){
      router.push(`/product?productno=${productno}`)
    }

    let productno = ref()
    let pic_url = ref()
    let sale_title = ref()
    let old_price = ref()
    let new_price = ref()
    let price_gap = ref()
    const time_balance = reactive({hour:0, minute:0, second:0})

    api.getFlashSale().then(response => {
      productno.value = response.productno
      pic_url.value = response.pic_url
      sale_title.value = response.sale_title
      old_price.value = fenToYuan(response.old_price)
      new_price.value = fenToYuan(response.new_price)
      price_gap.value = fenToYuan(response.old_price - response.new_price)

      window.setInterval(function (){
        let time_balance_a = calcTimeBalance(response.end_time)
        time_balance.hour = time_balance_a.hour
        time_balance.minute = time_balance_a.minute
        time_balance.second = time_balance_a.second
      },1000);

    })



    return {
      productno,
      old_price,
      new_price,
      price_gap,
      time_balance,
      pic_url,
      sale_title,
      toProductDetail

    }
  }
}
</script>

<style scoped>

.flash-sale-container{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.title-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #222222;
  padding: 20px 10px 16px 10px;
  height: 30px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background: linear-gradient(#f5e1ff, #faf1ff, #ffffff);
}

.time-info{
  display: flex;
}

.time{
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  line-height: 24px;
  text-align: center;
  background: url("../assets/images/icon_time_bg.png") no-repeat center center;
  background-size: cover;
}

.time-spot{
  width: 14px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: #f01400;
  text-align: center;
  box-sizing: border-box;
}

.flash-sale-product{
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 0 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.product{
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.flash-sale-info{
  display: flex;
  justify-content: space-between;
  background: linear-gradient(#ff6e91, #fe577f, #fc426d, #fa1e50);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 15px;
  height: 60px;
}

.old-price-container{
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: #ffffff;
  margin-left: 10px;
  min-width: 0;
}

.old-price{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: baseline;
  box-sizing: border-box;
}

.old-price .money-tag{
  font-size: 10px;
  font-weight: 600;
  margin-right: 4px;
}

.old-price .money-num{
  font-size: 16px;
  font-weight: 600;
  margin-right: 4px;
  text-decoration: line-through;
  text-decoration-color: #000000;
}

.old-price .old-price-tag{
  font-size: 10px;
  color: #f01400;
  background-color: #ffffff;
  padding: 0 6px;
  border-radius: 10px;
  align-self: center;
}

.title{
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-price-container{
  display: flex;;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  background: url("../assets/images/icon_new_price_bg.png") no-repeat center center;
  background-size: 100% 56px;
  margin-right: 2px;
}

.new-price{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  color: #f92053;
}

.new-price .money-tag{
  font-size: 10px;
  font-weight: 600;
  margin-right: 4px;
}

.new-price .money-num{
  font-size: 22px;
  font-weight: 600;
  margin-right: 4px;
}

.new-price .buy-tag{
  font-size: 22px;
  font-weight: 600;
  margin-right: 4px;
}

.desc{
  font-size: 14px;
  color: #f92053;
}

</style>
