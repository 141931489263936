<template>

  <div class="header">

    <!--城市切换和客服-->
    <Title :siteno="siteno" :fansno="fansno"></Title>

    <!--搜索框-->
    <SearchLine></SearchLine>

    <!--轮播图-->
    <HeaderSwiper :siteno="siteno" :fansno="fansno"></HeaderSwiper>

    <!--分类菜单-->
    <CategoryMenu currentSelectId="0"></CategoryMenu>

    <!--爆品排行榜-->
    <HotProduct :siteno="siteno" :fansno="fansno"></HotProduct>

    <!--限时秒杀-->
    <FlashSale :siteno="siteno" :fansno="fansno"></FlashSale>

    <!--排序菜单-->
<!--    <SortMenu></SortMenu>-->

  </div>


</template>

<script>
import Title from "./Title";
import SearchLine from "./SearchLine";
import HeaderSwiper from "./HeaderSwiper";
import CategoryMenu from "./CategoryMenu";
import HotProduct from "./HotProduct";
import FlashSale from "./FlashSale";

export default {
  name: "HomeHeader",
  props:{
    siteno:String,
    fansno:String
  },
  components: {FlashSale, HotProduct, CategoryMenu, HeaderSwiper, SearchLine, Title},
  setup(){
    return {
    }
  }
}
</script>

<style scoped>

/*头部*/
.header{
  width: 100%;
  background: linear-gradient(to bottom, #ffc82d, #fece49, #f6f6f6);
  background-size: 100% 100%;
  padding: 0 10px;
}

</style>
