
// 校验字符串是否为空
export function isEmptyStr(s) {
    if (s == undefined || s == null || s == '') {
        return true
    }
    return false
}

// 分转元
export function fenToYuan(fen){
    return fen * 0.01 + '';
}

//浮点数保留两位小数
//1.功能：将浮点数四舍五入，取小数点后2位
export function keepTwoDecimal(x) {
    let f = parseFloat(x);
    if (isNaN(f)) {
        return;
    }
    f = Math.round(x * 100) / 100;
    return f;
}

// 获取倒计时的剩余时间
export function calcTimeBalance(endTimeStr){
    //取当前系统时间
    let nowTime = new Date();
    //转换截止
    let endTime = new Date(endTimeStr);

    //时间差(毫秒数)
    let times = endTime.getTime() - nowTime.getTime();

    times = times / 1000; //转换为秒数

    let day = parseInt(times / 60 / 60 / 24);        //天数
    day = day < 0 ? 0 : day
    day = day > 9 ? day: '0' +day

    let hour = parseInt(times / 60 / 60 % 24);      //小时数
    if(day > 0)
    {
        hour = hour + 24 * day
    }
    hour = hour > 99 ? 99 : hour
    hour = hour < 0 ? 0 : hour
    hour = hour > 9 ? hour: '0' +hour

    let minute = parseInt(times / 60 % 60);         //分钟数
    minute = minute < 0 ? 0 : minute
    minute = minute > 9 ? minute: '0' +minute

    let second = parseInt(times % 60);              //秒数
    second = second < 0 ? 0 : second
    second = second > 9 ? second: '0' +second

    return {
        hour: hour,
        minute: minute,
        second: second
    }
}

//刷新siteno 和 fansno 的缓存
export function refreshSitenoAndFansno(siteno, fansno){

    if(!isEmptyStr(siteno) && isEmptyStr(localStorage.getItem("tuan_siteno")))
    {
        localStorage.setItem("tuan_siteno", siteno)
    }
    if(!isEmptyStr(fansno) && isEmptyStr(localStorage.getItem("tuan_fansno")))
    {
        localStorage.setItem("tuan_fansno", fansno)
    }
}

