<template>

  <div class="header">


    <!--城市切换和客服-->
    <Title></Title>
<!--    <div class="title-container">-->
<!--      <span class="title">{{site_title}}</span>-->
<!--      &lt;!&ndash;客服&ndash;&gt;-->
<!--      <div class="kefu-container" @click="showContact">-->
<!--        <img class="kefu" src="@/assets/images/icon_home_h5_kefu.png">-->
<!--        <span class="kefu-desc">返现</span>-->
<!--      </div>-->
<!--    </div>-->




    <!--搜索框-->
    <div @click="gotoSearch" class="search-box">
      <img src="@/assets/images/icon_home_search_blue.png">
      <span class="search-recommend">搜你想搜的!</span>
      <span>&nbsp;</span>
      <span class="search-btn">搜索</span>
    </div>



    <teleport to="body">

      <div @click="hideContact" v-if="isShowContact" class="contact-mask">
      </div>
      <div v-if="isShowContact" class="contact-container">
        <img src="https://tuan2022.oss-cn-hangzhou.aliyuncs.com/banner/banner1.png">
      </div>

    </teleport>

  </div>

  <div class="header-bottom-outer">
    <div class="header-bottom-inner">

    </div>
  </div>


<!--  <teleport to="body">-->

<!--    <div @click="closeChgArea" v-if="isShowChgArea" class="chg-area-mask">-->
<!--    </div>-->
<!--    <div v-if="isShowChgArea" class="chg-area-container">-->
<!--      <div class="top">请选择区域</div>-->
<!--      <div class="areas">-->
<!--        <span class="selected"><i class="iconfont icon-map"></i>全市</span>-->
<!--        <span>新城</span>-->
<!--        <span>市中区</span>-->
<!--        <span>滕州市</span>-->
<!--        <span>薛城区</span>-->
<!--        <span>峄城区</span>-->
<!--        <span>山亭区</span>-->
<!--        <span>台儿庄</span>-->
<!--      </div>-->
<!--    </div>-->

<!--  </teleport>-->

</template>

<script>

import Title from "./Title";

import {useRouter} from 'vue-router'
import {ref} from "vue";
import api from "../api";

export default {
  name: "PlayHeader",
  components: {Title},
  setup(){

    const router = useRouter()

    function gotoSearch(){
      router.push('/search')
    }


    let site_title = ref()

    api.getSiteInfo().then(response => {
      site_title.value = response.title
    })


    return {
      gotoSearch,
      site_title
    }
  }
}
</script>

<style scoped>

/*头部*/

.header{
  width: 100%;
  padding: 0 10px;
  background-color: #b4daff;
}

.header-bottom-outer{
  position: relative;
  width: 100%;
  height: 24px;
  background-color: #b4daff;
  margin-bottom: -10px;
}


.header-bottom-inner{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12px;
  background-color: #f6f6f6;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

/****************** 搜索框 ******************/
.search-box{
  display: flex;
  align-items: center;
  height: 34px;
  /*margin-top: 4px;*/
  margin: 4px 0 0 0;
  background-color: #fff;

  /*border: 1px solid #ffbe00;*/
  border-radius: 17px;
}

.search-box img{
  width: 20px;
  margin-left: 10px;
}

.search-box :nth-child(3){
  flex: 1;
}

.search-recommend{
  height: 25px;
  font-size: 12px;
  color: #999999;
  line-height: 25px;
  margin-left: 10px;
}

.search-btn{
  width: 48px;
  height: 26px;
  /*background-color: #1f60e1;*/
  /*background-color: #ffc82d;*/
  /*background: linear-gradient(to right, #ffdc4e, #ffc82d);*/
  /*color: #000000;*/
  background-color: #1f60e0;
  color: #ffffff;
  font-size: 12px;
  border-radius: 25px;
  text-align: center;
  line-height: 26px;
  margin-right: 4px;
}



/****************** 城市切换和客服 ******************/
.title-container{
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  height: 44px;
}

.location-container{
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}


.title{
  font-size: 15px;
  color: #000000;
  font-weight: 600;
}

.home-location-map{
  width: 14px;
  margin-right: 4px;
}

.home-location-down{
  width: 14px;
  margin-left: 4px;
}

.kefu-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  /*background-color: #fef3d3;*/
  background-color: #e6f0fa;
  border-radius: 15px;
  font-size: 12px;
}

.kefu{
  width: 16px;
  margin-left: 10px;
  margin-right: 4px;
}

.kefu-desc{
  margin-right: 10px;
}

.kefu-qrcode-container{
  position: fixed;
  top: 0;bottom: 0;left: 0;right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.kefu-qrcode{
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  /*top: 50%;*/
  /*left: 50%;*/
  /*transform: translate(-50%, -50%);*/
  text-align: center;
  width: 300px;
  height: 300px;
  z-index: 3;
}

.kefu-qrcode img{
  width: 100%;
}

/*切换区域*/
.chg-area-mask{
  position: fixed;
  top: 0;bottom: 0;left: 0;right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10;
}

.chg-area-container{
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 80%;
  z-index: 11;
  border-radius: 10px;
  overflow: hidden;
}

.top{
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  background-color: #eae8e8;
}

.areas{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #ffffff;
  font-size: 14px;
  padding: 20px 0;
}

.areas span{
  box-sizing: border-box;
  padding: 0 10px;
  margin: 10px 6px 10px 0;
  width: calc((100% - 30px)/3);
  height: 36px;
  line-height: 36px;
  text-align: center;
  border: 1px solid #eae8e8;
  border-radius: 8px;
}

.areas i{
  font-weight: 700;
  font-size: 16px;
  margin-right: 2px;
}

.areas span:nth-child(3n){
  margin-right: 0;
}

.areas .selected{
  font-weight: 500;
  color: #0f3ed2;
  border-color: #0f3ed2;
}



</style>
